<template>
  <div>
    <el-form>
      <el-row :gutter="50">
        <el-col :span="24">
          <div class="form-group">
            <el-form-item label="Content">
              <el-input
                v-model="field.content"
                @input="onInput"
                placeholder="Enter content and use @ to mention"
                maxlength="200"
              ></el-input>
              <ul v-if="showList" class="dropdown-list">
                <li
                  v-for="(label, index) in filteredFieldsList"
                  :key="index"
                  @click="selectField(label)"
                >
                  {{ label }}
                </li>
              </ul>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "templates-formComponents-SingleLineContent",
  props: ["field", "fieldsData"],
  data() {
    return {
      fieldsList: [],
      showList: false,
      filteredFieldsList: [],
    };
  },
  mounted() {
    this.field["filled_by"] = "SENDER";
    setTimeout(() => {
      if (Array.isArray(this.fieldsData)) {
        this.fieldsData.forEach((field) => {
          if (field && field.key) {
            this.fieldsList.push(field.key);
          }
        });
      }
    }, 500);
  },
  methods: {
    onInput() {
      const atIndex = this.field.content.lastIndexOf("@");
      if (atIndex >= 0) {
        const searchTerm = this.field.content.slice(atIndex + 1);
        this.filteredFieldsList = this.fieldsList.filter((label) =>
          label.toLowerCase().startsWith(searchTerm.toLowerCase())
        );
        this.showList = this.filteredFieldsList.length > 0;
      } else {
        this.showList = false;
      }
    },
    selectField(label) {
      const atIndex = this.field.content.lastIndexOf("@");
      this.field.content =
        this.field.content.slice(0, atIndex) + "@" + label + " ";
      this.field.selected_fields.push(label);
      this.showList = false;
    },
  },
};
</script>

<style scoped>
.dropdown-list {
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}
</style>
